<script setup>
import { usePage } from '@inertiajs/vue3';
import { ref, computed, onMounted } from 'vue';
import Popup from './Popup.vue';

const popups = computed(() => usePage().props.shop.popups);

const popup = ref(null);

const getNextPopup = () => {
    popup.value = popups.value.shift();
};

onMounted(() => {
    if (popups.value.length) {
        getNextPopup();
    }
});
</script>

<template>
    <Popup v-if="popup" :popup="popup" @close="getNextPopup" />
</template>
