<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    hasError: {
        type: Boolean,
    },
});

const model = defineModel({
    type: [String, Number],
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value?.focus();
    }
});

defineExpose({
    focus: () => input.value?.focus(),
});
</script>

<template>
    <input
        ref="input"
        v-model="model"
        class="w-full h-auto border-t-0 border-b-2 border-l-0 border-r-0 rounded-none outline-none input lg:text-7xl border-primary focus:border-primary focus:outline-none text-primary placeholder:text-primary/40"
        :class="{ 'input-error': hasError }"
    />
</template>
