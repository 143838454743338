<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    popup: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['close']);

const modal = ref(null);

const onClose = () => emit('close');

const onMarkAsSeen = () => {
    router.post(
        route('popups.mark-as-seen', { popup: props.popup }),
        {},
        { onFinish: onClose },
    );
};

onMounted(() => modal.value?.showModal());
onMounted(() => modal.value?.addEventListener('close', onClose));
onBeforeUnmount(() => modal.value?.removeEventListener('close', onClose));

watch(
    () => props.popup,
    () => modal.value?.showModal(),
);
</script>

<template>
    <dialog ref="modal" class="modal modal-bottom sm:modal-middle">
        <div class="modal-box">
            <form method="dialog">
                <button
                    class="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
                >
                    ✕
                </button>
            </form>

            <div class="space-y-6">
                <h3 class="text-lg font-bold text-primary">
                    {{ popup.title }}
                </h3>

                <p>
                    {{ popup.content }}
                </p>

                <img
                    v-if="popup.image"
                    :src="popup.image"
                    alt=""
                    class="w-full h-auto"
                />

                <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <button class="btn btn-block" @click="onClose">
                        {{ $t('popup.close') }}
                    </button>
                    <button
                        class="btn btn-primary btn-block"
                        @click="onMarkAsSeen"
                    >
                        {{ $t('popup.dont_show_again') }}
                    </button>
                </div>
            </div>
        </div>

        <form method="dialog" class="modal-backdrop">
            <button>close</button>
        </form>
    </dialog>
</template>
