<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { Link } from '@inertiajs/vue3';
import ArrowRightBoldIcon from '@assets/icons/arrow_right_bold.svg';
import CartIcon from '@assets/icons/cart.svg';
import HeartIcon from '@assets/icons/heart.svg';
import ParcelIcon from '@assets/icons/parcel.svg';
import CogIcon from '@assets/icons/cog.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import TrashIcon from '@assets/icons/trash.svg';
import { useCurrentUser } from '@/Composables/useCurrentUser';
import ArrowLink from '@/Components/ArrowLink.vue';

defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);

const user = computed(() => useCurrentUser());

const menu = ref(null);

const close = () => emit('close');

onClickOutside(menu, close);
</script>

<template>
    <div
        ref="menu"
        class="grid grid-cols-1 sub-menu md:grid-cols-3"
        :class="{ hidden: !isOpen }"
    >
        <div class="mb-8">
            <ArrowLink url="#" @click="close">
                {{ $t('navigation.product_finder') }}
            </ArrowLink>
            <ArrowLink :url="route('wiki')" @click="close">
                {{ $t('navigation.wiki') }}
            </ArrowLink>
            <ArrowLink :url="route('faq')" @click="close">
                {{ $t('navigation.faq') }}
            </ArrowLink>
        </div>
        <ul class="space-y-4">
            <li>
                <Link
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('cart.index')"
                    @click="close"
                >
                    <CartIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.cart') }}
                </Link>
            </li>
            <li v-if="user">
                <Link
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('wishlist.index')"
                    @click="close"
                >
                    <HeartIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.wishlist') }}
                </Link>
            </li>
            <li>
                <Link
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('orders.index')"
                    @click="close"
                >
                    <ParcelIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.orders') }}
                </Link>
            </li>
        </ul>
        <ul class="space-y-4">
            <li v-if="user">
                <Link
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('profile.edit') + '#account-settings'"
                    @click="close"
                >
                    <CogIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.account_settings') }}
                </Link>
            </li>
            <li v-if="user">
                <Link
                    method="post"
                    as="button"
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('logout')"
                    @click="close"
                >
                    <LogoutIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.logout') }}
                </Link>
            </li>
            <li v-else>
                <Link
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('login')"
                    @click="close"
                >
                    <ArrowRightBoldIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.login') }}
                </Link>
            </li>
            <li v-if="user">
                <Link
                    class="flex items-center gap-2 hover:text-primary"
                    :href="route('profile.edit') + '#delete-account'"
                    @click="close"
                >
                    <TrashIcon class="w-auto h-4 text-primary" />
                    {{ $t('navigation.delete_account') }}
                </Link>
            </li>
        </ul>
    </div>
</template>
