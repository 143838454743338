<script setup>
import { Link } from '@inertiajs/vue3';
import ArrowRight from '@assets/icons/arrow_right.svg';

const emit = defineEmits(['click']);

defineProps({
    title: {
        type: String,
        required: true,
    },
    productType: {
        type: Object,
    },
    material: {
        type: Object,
    },
    image: {
        type: String,
    },
    url: {
        type: String,
        required: true,
    },
});

const onClick = () => emit('click');
</script>

<template>
    <Link
        :href="url"
        class="flex flex-row items-center w-full gap-4 p-4 text-sm rounded-lg bg-base-200/30 card hover:ring-1 hover:ring-inset hover:ring-base-200 group"
        @click="onClick"
    >
        <div v-if="image" class="w-6 h-6 shrink-0 aspect-auto" aria-hidden>
            <img :src="image" class="w-full h-full" />
        </div>
        <img v-if="productType?.icon" :src="productType.icon" class="w-5 h-5" />
        <img v-if="material?.icon" :src="material.icon" class="w-5 h-5" />
        <div class="flex max-sm:flex-col flex-wrap gap-x-8 gap-y-1">
            <div class="font-semibold text-primary">
                {{ title }}
            </div>
            <div v-if="material" class="flex gap-8 font-mono">
                <div>
                    {{ material?.parent?.title }}
                </div>
                <div>
                    {{ material?.title }}
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end flex-grow">
            <div
                class="flex items-center justify-center w-10 h-10 min-h-0 p-0 rounded-full bg-white text-primary group-hover:text-primary-content group-hover:bg-primary transition-colors"
            >
                <ArrowRight class="relative w-4 h-4" />
            </div>
        </div>
    </Link>
</template>
