<script setup>
import { ref, computed, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import axios from 'axios';
import SearchInput from '@/Components/SearchInput.vue';
import SearchResult from '@/Components/SearchResult.vue';
import { onClickOutside } from '@vueuse/core';

defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);

defineExpose({
    focus: () => input.value?.focus(),
});

const menu = ref(null);
const query = ref('');
const input = ref(null);
const productTypes = ref([]);
const materials = ref([]);
const products = ref([]);

const searchKeywords = computed(() => usePage().props.shop.searchKeywords);

const onSearch = async (query) => {
    try {
        if (!query) {
            productTypes.value = [];
            materials.value = [];
            products.value = [];

            return;
        }

        const result = await axios.post(route('shop.search'), {
            query,
        });

        productTypes.value = result.data.productTypes ?? [];
        materials.value = result.data.materials ?? [];
        products.value = result.data.products ?? [];
    } catch (error) {
        console.error(error);
    }
};

const close = () => emit('close');

const onSelectKeyword = (keyword) => {
    query.value = query.value ? `${query.value} ${keyword}` : keyword;
};

watch(query, useDebounceFn(onSearch, 500));

onClickOutside(menu, close);
</script>

<template>
    <div ref="menu" class="sub-menu" :class="{ hidden: !isOpen }">
        <SearchInput
            ref="input"
            v-model="query"
            :placeholder="$t('navigation.search_placeholder')"
        />
        <div class="flex flex-row flex-wrap gap-2 my-8">
            <div
                v-for="searchKeyword in searchKeywords"
                :key="searchKeyword"
                class="font-mono cursor-pointer badge badge-lg badge-primary badge-outline"
                @click="onSelectKeyword(searchKeyword)"
            >
                {{ searchKeyword }}
            </div>
        </div>
        <div class="max-w-screen-xl space-y-8">
            <ul v-if="productTypes.length" class="space-y-4">
                <li v-for="productType in productTypes" :key="productType.id">
                    <SearchResult
                        :title="productType.title"
                        :image="productType.icon || productType.image"
                        :url="
                            route('shop.index', {
                                productTypeIds: [productType.id],
                            })
                        "
                        @click="close"
                    />
                </li>
            </ul>
            <ul v-if="materials.length" class="space-y-4">
                <li v-for="material in materials" :key="material.id">
                    <SearchResult
                        :title="material.title"
                        :image="material.icon || material.image"
                        :url="
                            route('shop.index', {
                                materialIds: [material.id],
                            })
                        "
                        @click="close"
                    />
                </li>
            </ul>
            <ul v-if="products.length" class="space-y-4">
                <li v-for="product in products" :key="product.id">
                    <SearchResult
                        :title="product.title"
                        :material="product.material"
                        :image="product.image"
                        :url="
                            route('shop.show-product', {
                                product: product.id,
                            })
                        "
                        @click="close"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
