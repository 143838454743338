<script setup>
import { ref, computed, nextTick } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import Logo from '@assets/logos/logo.svg';
import HamburgerIcon from '@assets/icons/hamburger.svg';
import SearchIcon from '@assets/icons/search.svg';
import CartIcon from '@assets/icons/cart.svg';
import UserIcon from '@assets/icons/user.svg';
import { useCart } from '@/Composables/useCart';
import { useCurrentLocale } from '@/Composables/useCurrentLocale';
import SearchMenu from './SearchMenu.vue';
import Menu from './Menu.vue';
import ProductTypesMenu from './ProductTypesMenu.vue';
import MaterialsMenu from './MaterialsMenu.vue';
import AccountMenu from './AccountMenu.vue';
import { useCountries } from '@/Composables/useCountries';

const isMenuOpen = ref(false);
const isSearchMenuOpen = ref(false);
const isProductTypesMenuOpen = ref(false);
const isMaterialsMenuOpen = ref(false);
const isAccountMenuOpen = ref(false);
const menu = ref(null);
const searchMenu = ref(null);
const productTypesMenu = ref(null);
const materialsMenu = ref(null);
const accountMenu = ref(null);

const { getCountryNameFromCode } = useCountries();
const currentLocale = computed(() => useCurrentLocale());
const cart = computed(() => useCart());
const cartItems = computed(() => cart.value?.getItemCount() || 0);
const locales = computed(() => usePage().props.config.locales);

const showMenu = () => (isMenuOpen.value = true);

const closeMenu = () => (isMenuOpen.value = false);

const showSearchMenu = () => {
    closeMenu();

    isSearchMenuOpen.value = true;

    nextTick(() => searchMenu.value?.focus());
};

const closeSearchMenu = () => (isSearchMenuOpen.value = false);

const showProductTypesMenu = () => {
    closeMenu();

    isProductTypesMenuOpen.value = true;
};

const closeProductTypesMenu = () => (isProductTypesMenuOpen.value = false);

const showMaterialsMenu = () => {
    closeMenu();

    isMaterialsMenuOpen.value = true;
};

const closeMaterialsMenu = () => (isMaterialsMenuOpen.value = false);

const showAccountMenu = () => {
    closeMenu();

    isAccountMenuOpen.value = true;
};

const closeAccountMenu = () => (isAccountMenuOpen.value = false);

const isAnyMenuOpen = computed(() =>
    isMenuOpen.value
    || isSearchMenuOpen.value
    || isProductTypesMenuOpen.value
    || isMaterialsMenuOpen.value
    || isAccountMenuOpen.value
);

const changeLocale = (locale) => {
    const currentLocation = window.location.href;
    const newLocation = currentLocation.replace(
        currentLocale.value.locale,
        locale,
    );

    window.location.href = newLocation;
};

const scrollY = ref(window.scrollY);

window.addEventListener('scroll', () => {
    scrollY.value = window.scrollY;
});
</script>

<template>
    <nav class="fixed top-0 z-20 w-full">
        <div
            :class="{ 'bg-base-100': scrollY || isAnyMenuOpen }"
        >
            <div class="page-container navbar justify-between gap-x-8 p-4">
                <div>
                    <Link
                        class="text-xl text-primary hover:text-accent transition-colors"
                        :href="route('home')"
                    >
                        <Logo class="w-auto h-8" />
                    </Link>
                </div>

                <div>
                    <button class="p-0 btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent lg:hidden" @click="showMenu">
                        <HamburgerIcon class="w-8 h-8" />
                    </button>
                    <div
                        class="items-center justify-center hidden gap-2 lg:flex"
                    >
                        <Link
                            class="btn btn-sm btn-primary hover:bg-accent hover:border-accent"
                            :href="route('shop.index')"
                        >
                            {{ $t('navigation.shop') }}
                        </Link>
                        <button
                            type="button"
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            @click="showProductTypesMenu"
                        >
                            {{ $t('navigation.product_range') }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            @click="showMaterialsMenu"
                        >
                            {{ $t('navigation.material') }}
                        </button>
                        <Link
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            :href="route('services')"
                        >
                            {{ $t('navigation.services') }}
                        </Link>
                        <Link
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            :href="route('about')"
                        >
                            {{ $t('navigation.about') }}
                        </Link>
                        <Link
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            :href="route('contact')"
                        >
                            {{ $t('navigation.support') }}
                        </Link>
                        <button
                            type="button"
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            :aria-label="$t('navigation.search')"
                            @click="showSearchMenu"
                        >
                            <SearchIcon class="w-6 h-6" />
                        </button>
                        <Link
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            :href="route('cart.index')"
                            :aria-label="$t('navigation.cart')"
                        >
                            <div class="indicator">
                                <CartIcon class="w-6 h-6" />
                                <span
                                    v-if="cartItems"
                                    class="badge badge-success badge-xs indicator-item"
                                >
                                </span>
                            </div>
                        </Link>
                        <button
                            type="button"
                            class="btn btn-sm btn-ghost text-primary hover:text-accent hover:bg-transparent"
                            :aria-label="$t('navigation.account')"
                            @click="showAccountMenu"
                        >
                            <UserIcon class="w-6 h-6" />
                        </button>
                        <div class="dropdown dropdown-end">
                            <div
                                tabindex="0"
                                role="button"
                                class="btn btn-sm btn-outline btn-primary hover:!bg-accent hover:!border-accent text-nowrap !rounded-lg uppercase"
                            >
                                {{ currentLocale.language }}
                            </div>
                            <ul
                                tabindex="0"
                                class="w-56 p-2 shadow dropdown-content menu bg-base-100 rounded-box"
                            >
                                <li
                                    v-for="locale in locales"
                                    :key="locale.locale"
                                >
                                    <a
                                        :class="{
                                            active:
                                                locale.locale ===
                                                currentLocale.locale,
                                        }"
                                        @click="changeLocale(locale.locale)"
                                    >
                                        {{ $t('language.' + locale.language) }}
                                        ({{
                                            getCountryNameFromCode(
                                                locale.country_code,
                                            )
                                        }})
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="{ 'h-screen bg-black/50': isAnyMenuOpen }"
        >
            <Menu
                ref="menu"
                :is-open="isMenuOpen"
                @close="closeMenu"
                @show-search-menu="showSearchMenu"
                @show-product-types-menu="showProductTypesMenu"
                @show-materials-menu="showMaterialsMenu"
                @change-locale="changeLocale"
            />
            <SearchMenu
                ref="searchMenu"
                :is-open="isSearchMenuOpen"
                @close="closeSearchMenu"
            />
            <ProductTypesMenu
                ref="productTypesMenu"
                :is-open="isProductTypesMenuOpen"
                @close="closeProductTypesMenu"
            />
            <MaterialsMenu
                ref="materialsMenu"
                :is-open="isMaterialsMenuOpen"
                @close="closeMaterialsMenu"
            />
            <AccountMenu
                ref="accountMenu"
                :is-open="isAccountMenuOpen"
                @close="closeAccountMenu"
            />
        </div>
    </nav>
</template>
