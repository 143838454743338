<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { usePage, Link } from '@inertiajs/vue3';
import ArrowLink from '@/Components/ArrowLink.vue';

defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);

const menu = ref(null);

const productTypes = computed(() => usePage().props.shop.productTypes);

const close = () => emit('close');

onClickOutside(menu, close);
</script>

<template>
    <div
        ref="menu"
        class="sub-menu sub-menu-columns"
        :class="{ hidden: !isOpen }"
    >
        <div class="mb-8 md:break-after-column break-after-auto">
            <ArrowLink url="#" @click="close">
                {{ $t('navigation.product_finder') }}
            </ArrowLink>
            <ArrowLink :url="route('wiki')" @click="close">
                {{ $t('navigation.wiki') }}
            </ArrowLink>
            <ArrowLink :url="route('faq')" @click="close">
                {{ $t('navigation.faq') }}
            </ArrowLink>
        </div>
        <ul class="space-y-4">
            <template v-for="productType in productTypes" :key="productType.id">
                <li>
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="
                            route('shop.index', {
                                productTypeIds: [productType.id],
                            })
                        "
                        @click="close"
                    >
                        <div
                            v-if="productType.icon"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img
                                :src="productType.icon"
                                class="w-full h-full"
                            />
                        </div>
                        <div
                            v-else-if="productType.image"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img
                                :src="productType.image"
                                class="object-cover w-full h-full"
                            />
                        </div>
                        {{ productType.title }}
                    </Link>
                </li>
                <li v-for="child in productType.children" :key="child.id">
                    <Link
                        class="flex items-center gap-2 hover:text-primary"
                        :href="
                            route('shop.index', {
                                productTypeIds: [productType.id],
                            })
                        "
                        @click="close"
                    >
                        <div
                            v-if="child.icon"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img :src="child.icon" class="w-full h-full" />
                        </div>
                        <div
                            v-else-if="child.image"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img
                                :src="child.image"
                                class="object-cover w-full h-full"
                            />
                        </div>
                        {{ child.title }}
                    </Link>
                </li>
            </template>
        </ul>
    </div>
</template>
